import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// form
import { FormControl, FormControlLabel, Stack, FormHelperText, FormLabel, Radio, RadioGroup, Tooltip, } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocales } from '../../locales';
import Iconify from '../iconify';
export default function RHFRadioGroup({ row, name, label, options, spacing, helperText, ...other }) {
    const { control } = useFormContext();
    const labelledby = label ? `${name}-${label}` : '';
    const { translate } = useLocales();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(FormControl, { component: "fieldset", children: [label && (_jsx(FormLabel, { component: "legend", id: labelledby, sx: { typography: 'body2' }, children: `${translate(label)}` })), _jsxs(Stack, { sx: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: '10px',
                        width: '100%',
                    }, children: [_jsx(RadioGroup, { ...field, "aria-labelledby": labelledby, row: row, value: field.value !== undefined && !Number.isNaN(field.value) && field.value, onBlur: field.onBlur, ...other, children: options.map((option) => (_jsx(FormControlLabel, { value: option.value !== undefined &&
                                    !Number.isNaN(option.value) &&
                                    option.value.toString(), control: _jsx(Radio, {}), label: `${translate(option.label)}`, sx: {
                                    '&:not(:last-of-type)': {
                                        mb: spacing || 0,
                                    },
                                    ...(row && {
                                        mr: 0,
                                        '&:not(:last-of-type)': {
                                            mr: spacing || 2,
                                        },
                                    }),
                                } }, option.value !== undefined &&
                                !Number.isNaN(option.value) &&
                                option.value.toString()))) }), _jsx(Tooltip, { title: `${translate(helperText)}` || `${translate(label)}`, placement: "right-start", children: _jsx(Iconify, { icon: "ri:information-line", width: 23, sx: { cursor: 'help', color: '#9AA6B2' } }) })] }), !!error && (_jsx(FormHelperText, { error: !!error, children: `${translate(error?.message)}` }))] })) }));
}
